const Invariable = {
	logoFonts: ["Fira Code, monospace"],
	fonts: ["Inter, sans-serif"],
	fontSizes: {
		xxsmall: "1.4rem",
		xsmall: "1.8rem",
		small: "2rem",
		mediumSmall: "2.7rem",
		medium: "3.2rem",
		large: "4rem",
		xlarge: "5.4rem",
		xxlarge: "6.4rem",
	},
	mobileFontRatio: {
		xxsmall: 1,
		xsmall: 1,
		small: 1,
		mediumSmall: 1,
		medium: 1,
		large: 1,
		xlarge: 0.695,
		xxlarge: 1,
	},
	breakpoints: {
		// By screen res
		uhd: 3840,
		wqhd: 2560,
		fhd: 1920,
		hd: 1366,
		// defaults below
		xxl: 1440,
		xl: 1200,
		lg: 992,
		md: 768,
		sm: 576,
		xs: 575,
	},
	container: {
		padding: 15, // default 15
		maxWidth: {
			// defaults below
			xxl: 1200,
			xl: 1140,
			lg: 960,
			md: 720,
			sm: 540,
			xs: 540,
			// or you can use aliases
			// veryGiant: 1141,
			// giant: 1140,
			// desktop: 960,
			// tablet: 720,
			// phone: 540,
			// smaller: 540,
		},
	},
};

export const DarkTheme = {
	colors: {
		primary: "#23d997",
		primaryHover: "#20b47e",
		foreground01: "#FFFFFF",
		foreground02: "#f5f5f5",
		foreground03: "#e3e3e3",
		foreground04: "#CCC",
		foreground05: "#b3b3b3",
		background05: "#4d4d4d",
		background04: "#333333",
		background03: "#1b1b1b",
		background02: "#0A0A0A",
		background01: "#000000",
		white: "#FFFFFF",
	},
	...Invariable,
};

export const LightTheme = {
	colors: {
		primary: "#23d997",
		primaryHover: "#20b47e",
		foreground01: "#000000",
		foreground02: "#0A0A0A",
		foreground03: "#1b1b1b",
		foreground04: "#333333",
		foreground05: "#4d4d4d",
		background05: "#b3b3b3",
		background04: "#ccc",
		background03: "#e3e3e3",
		background02: "#f5f5f5",
		background01: "#FFFFFF",
		white: "#FFFFFF",
	},
	...Invariable,
};
