// * IMPORTS
// UseState Import
import { useState } from "react";
// Pages Import
import AboutUs from "./pages/AboutUs";
import Nav from "./components/Nav";
import ContactUs from "./pages/ContactUs";
import OurWork from "./pages/OurWork";
import MovieDetail from "./pages/movieDetail";
// Style Import
import GlobalStyle from "./style/GlobalStyle";
import { ThemeProvider } from "styled-components";
import { DarkTheme, LightTheme } from "./style/themes";
// React Modules Import
import { Switch, Route, useLocation, Router } from "react-router-dom";
// Animation
import { AnimatePresence } from "framer-motion";
import { AuthProvider } from "./helpers/AuthContext";
import history from "./helpers/history";
import SignupSuccess from "./pages/SignupSuccess";
import Signup from "./pages/SignUp/Signup";
import Login from "./pages/Login/Login";
import PrivateRoute from "./components/PrivateRoute";

function App() {
	//*State
	const [theme, setTheme] = useState("dark");

	// Location is needed for for framer motion to animate page change
	const location = useLocation();
	return (
		<ThemeProvider theme={theme === "light" ? LightTheme : DarkTheme}>
			<div className='App'>
				<GlobalStyle />
				<AuthProvider>
					<Router history={history}>
						<Switch location={location} key={location.pathname}>
							<Route path='/domoaligato' exact>
								<Signup />
							</Route>
							<Route path='/login' exact>
								<Login />
							</Route>
							<Route path='/signup-success' exact>
								<SignupSuccess />
							</Route>
						</Switch>
					</Router>
					<Nav />

					<AnimatePresence exitBeforeEnter>
						<Router history={history}>
							<Switch location={location} key={location.pathname}>
								<PrivateRoute path='/' exact>
									<AboutUs theme={theme} setTheme={setTheme} />
								</PrivateRoute>
								<PrivateRoute path='/work' exact>
									<OurWork />
								</PrivateRoute>
								<PrivateRoute path='/work/:id'>
									<MovieDetail />
								</PrivateRoute>
								<PrivateRoute path='/contact'>
									<ContactUs />
								</PrivateRoute>
							</Switch>
						</Router>
					</AnimatePresence>
				</AuthProvider>
			</div>
		</ThemeProvider>
	);
}

export default App;
