import AboutSection from "../components/aboutSection";
import ServiceSection from "../components/ServicesSection";
import FaqSection from "../components/FaqSection";
//* Animations
import { motion } from "framer-motion";
import { pageAnimation } from "../style/animation";
import ScrollTop from "../components/ScrollTop";

const AboutUs = ({ theme, setTheme }) => {
	return (
		<motion.div
			variants={pageAnimation}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<ScrollTop />
			<AboutSection theme={theme} setTheme={setTheme} />
			<ServiceSection />
			<FaqSection />
		</motion.div>
	);
};
export default AboutUs;
