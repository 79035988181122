import history from "../../../helpers/history";

export async function AccountCreation(
	values,
	setLoading,
	setError,
	signup,
	logout
) {
	const email = `user.${values.password
		.substring(0, 3)
		.toLowerCase()}@cedricguindon.com`;
	values = { ...values, email: email };
	// same shape as initial values
	try {
		setError("");
		setLoading(true);
		await signup(email, values.password).then(() => {
			logout();
		});
		setLoading(false);
		history.push("/signup-success");
	} catch {
		setError("Failed to create an account");
		setLoading(false);
	}
}
