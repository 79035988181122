import clock from "../img/clock.svg";
import diaphragm from "../img/diaphragm.svg";
import money from "../img/money.svg";
import teamwork from "../img/teamwork.svg";
import home2 from "../img/home2.png";
//* Styling
import {
	ØSection,
	ØDescription,
	ØImageContainer,
} from "../style/StyledComponents";
import styled from "styled-components";
import { scrollReveal } from "../style/animation";
import { useScroll } from "./useScroll";
const ServicesSection = () => {
	const [element, controls] = useScroll();
	return (
		<ØServices
			ref={element}
			variants={scrollReveal}
			animate={controls}
			initial='hidden'
		>
			<ØDescription>
				<h2>
					High <span>quality</span> services
				</h2>
				<ØCards>
					<ØCard>
						<div className='icon'>
							<img src={clock} alt='Clock icon' />
							<h3>Efficient</h3>
						</div>
						<p>Lorem ipsum dolor sit amet.</p>
					</ØCard>
					<ØCard>
						<div className='icon'>
							<img src={teamwork} alt='TeamWork icon' />
							<h3>Teamwork</h3>
						</div>
						<p>Lorem ipsum dolor sit amet.</p>
					</ØCard>
					<ØCard>
						<div className='icon'>
							<img src={diaphragm} alt='Diaphragm icon' />
							<h3>Diaphragm</h3>
						</div>
						<p>Lorem ipsum dolor sit amet.</p>
					</ØCard>
					<ØCard>
						<div className='icon'>
							<img src={money} alt='Money icon' />
							<h3>Affordable</h3>
						</div>
						<p>Lorem ipsum dolor sit amet.</p>
					</ØCard>
				</ØCards>
			</ØDescription>
			<ØImageContainer>
				<img src={home2} alt='Camera' />
			</ØImageContainer>
		</ØServices>
	);
};

const ØServices = styled(ØSection)`
	h2 {
		padding-bottom: 5rem;
	}
	p {
		width: 70%;
		padding: 2rem 0rem 4rem 0rem;
	}
`;
const ØCards = styled.div`
	display: flex;
	flex-wrap: wrap;
`;
const ØCard = styled.div`
	@media (max-width: 1300px) {
		justify-content: center;
	}
	flex-basis: 20rem;
	.icon {
		display: flex;
		align-items: center;
		h3 {
			margin-left: 1rem;
			background: ${(props) => props.theme.colors.foreground01};
			color: ${(props) => props.theme.colors.background03};
			padding: 1rem;
		}
	}
`;
export default ServicesSection;
