import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useAuth } from "../helpers/AuthContext";
const Nav = () => {
	const { logout } = useAuth();
	const { pathname } = useLocation();
	return (
		<ØNav>
			<h1>
				<Link id='Logo' to='/'>
					Capture
				</Link>
			</h1>
			<ul>
				<li>
					<Link to='/'>1. About Us</Link>
					<ØLine
						transition={{ duration: 0.75 }}
						initial={{ width: "0%" }}
						animate={{ width: pathname === "/" ? "50%" : "0%" }}
					/>
				</li>
				<li>
					<Link to='/work'>2. Our Work</Link>
					<ØLine
						transition={{ duration: 0.75 }}
						initial={{ width: "0%" }}
						animate={{ width: pathname === "/work" ? "50%" : "0%" }}
					/>
				</li>
				<li>
					<Link to='/contact'>3. Contact Us</Link>
					<ØLine
						transition={{ duration: 0.75 }}
						initial={{ width: "0%" }}
						animate={{ width: pathname === "/contact" ? "50%" : "0%" }}
					/>
				</li>
				<li>
					<button onClick={logout}>Logout</button>
					<ØLine
						transition={{ duration: 0.75 }}
						initial={{ width: "0%" }}
						animate={{ width: pathname === "/contact" ? "50%" : "0%" }}
					/>
				</li>
			</ul>
		</ØNav>
	);
};
const ØNav = styled.nav`
	min-height: 10vh;
	display: flex;
	margin: auto;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 10rem;
	position: sticky;
	top: 0;
	z-index: 5;
	background: ${(props) => props.theme.colors.background04};
	a {
		color: ${(props) => props.theme.colors.foreground01};
		text-decoration: none;
	}
	ul {
		display: flex;
		list-style: none;
	}
	#Logo {
		font-family: ${(props) => props.theme.logoFonts};
		font-size: ${(props) => props.theme.fontSizes.medium};
		font-style: lighter;
	}
	li {
		padding-left: 10rem;
		position: relative;
	}
	@media (max-width: 1300px) {
		flex-direction: column;
		padding: 2rem 1rem;
		#Logo {
			display: inline-block;
			margin: 2rem;
		}
		ul {
			padding: 2rem;
			justify-content: space-around;
			width: 100%;
			li {
				padding: 0;
			}
		}
	}
`;

const ØLine = styled(motion.div)`
	height: 0.3rem;
	background: #23d997;
	width: 0%;
	position: absolute;
	bottom: -80%;
	left: 60%;
	@media (max-width: 1300px) {
		left: 0%;
	}
`;

export default Nav;
