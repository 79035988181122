import styled from "styled-components";
import { Link } from "react-router-dom";
// Images
import athlete from "../img/athlete-small.png";
import theracer from "../img/theracer-small.png";
import goodtimes from "../img/goodtimes-small.png";
//* Animations
import { useScroll } from "../components/useScroll";
import { motion } from "framer-motion";
import {
	pageAnimation,
	fade,
	photoAnim,
	lineAnim,
	slider,
	sliderContainer,
} from "../style/animation";
import ScrollTop from "../components/ScrollTop";

const OurWork = () => {
	const [element, controls] = useScroll();
	const [element2, controls2] = useScroll();

	return (
		<ØWork
			layout='position'
			variants={pageAnimation}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<ScrollTop />
			<motion.div variants={sliderContainer}>
				<ØFrame1 variants={slider}></ØFrame1>
				<ØFrame2 variants={slider}></ØFrame2>
				<ØFrame3 variants={slider}></ØFrame3>
				<ØFrame4 variants={slider}></ØFrame4>
			</motion.div>
			<ØMovie
				initial={{ opacity: 0 }}
				animate={{
					opacity: 1,
					transition: {
						delay: 1,
						ease: "easeOut",
						duration: 0.75,
					},
				}}
			>
				<motion.h2 variants={fade}>The Athlete</motion.h2>
				<motion.div variants={lineAnim} className='line'></motion.div>
				<Link to='/work/the-athlete'>
					<ØHide>
						<motion.img variants={photoAnim} src={athlete} alt='An Athlete' />
					</ØHide>
				</Link>
			</ØMovie>
			<ØMovie variants={fade} animate={controls} ref={element} initial='hidden'>
				<h2>The Racer</h2>
				<motion.div variants={lineAnim} className='line'></motion.div>
				<Link to='/work/the-racer'>
					<img src={theracer} alt='The Racer Movie' />
				</Link>
			</ØMovie>
			<ØMovie
				variants={fade}
				animate={controls2}
				ref={element2}
				initial='hidden'
			>
				<h2>Good Times</h2>
				<motion.div variants={lineAnim} className='line'></motion.div>
				<Link to='/work/good-times'>
					<img src={goodtimes} alt='Goodtimes Movie' />
				</Link>
			</ØMovie>
		</ØWork>
	);
};

const ØWork = styled(motion.div)`
	@media (max-width: 1300px) {
		padding: 2rem 2rem;
	}
	min-height: 100vh;
	overflow: hidden;
	padding: 5rem 10rem;
	/* background: white; */
	h2 {
		padding: 1rem 0rem;
		color: ${(props) => props.theme.colors.foreground01};
	}
`;
const ØMovie = styled(motion.div)`
	padding-bottom: 10rem;
	.line {
		height: 0.5rem;
		background: ${(props) => props.theme.colors.primary};
		margin-bottom: 3rem;
	}
	img {
		width: 100%;
		height: 70vh;
		object-fit: cover;
	}
`;

const ØHide = styled.div`
	overflow: hidden;
`;

// frame Animation
const ØFrame1 = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #fffebf;
	z-index: 2;
`;
const ØFrame2 = styled(ØFrame1)`
	background: #ff8efb;
`;
const ØFrame3 = styled(ØFrame1)`
	background: #8ed2ff;
`;
const ØFrame4 = styled(ØFrame1)`
	background: #8effa0;
`;
export default OurWork;
