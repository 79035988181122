//* Import
// Styling
import { ØSection } from "../style/StyledComponents";
import styled from "styled-components";
import { scrollReveal } from "../style/animation";
// Components
import Toggle from "./Toggle";
// Modules
import DeltaE from "delta-e";
import convert from "color-convert";
import { AnimateSharedLayout } from "framer-motion";
// React Modules
import { useScroll } from "./useScroll";
// Variables
const color1 = { L: 50, A: 60, B: 41 };
const color2 = { L: 50, A: 60, B: 46 };
const hexColor1 = convert.lab.hex(color1.L, color1.A, color1.B);
const hexColor2 = convert.lab.hex(color2.L, color2.A, color2.B);

const FaqSection = () => {
	const [element, controls] = useScroll();
	return (
		<ØFaq
			ref={element}
			variants={scrollReveal}
			animate={controls}
			initial='hidden'
		>
			<h2>
				Any Questions ?<span>FAQ</span>
			</h2>
			<AnimateSharedLayout>
				<Toggle title='How do I Start?'>
					<div className='answer'>
						<p>Lorem ipsum dolor sit amet.</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
							repellat.
						</p>
					</div>
				</Toggle>
			</AnimateSharedLayout>
			<AnimateSharedLayout>
				<Toggle title='Daily Schedule'>
					<div className='answer'>
						<p>Lorem ipsum dolor sit amet.</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
							repellat.
						</p>
					</div>
				</Toggle>
			</AnimateSharedLayout>
			<AnimateSharedLayout>
				<Toggle title='Different Payment Methods'>
					<div className='answer'>
						<p>Lorem ipsum dolor sit amet.</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
							repellat.
						</p>
					</div>
				</Toggle>
			</AnimateSharedLayout>
			<AnimateSharedLayout>
				<Toggle title='What product do you offer?'>
					<div className='answer'>
						<p>Lorem ipsum dolor sit amet.</p>
						<p>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus,
							repellat.
						</p>
					</div>
				</Toggle>
			</AnimateSharedLayout>
			<div>
				<Øsquare1></Øsquare1>
				<Øsquare2></Øsquare2>
				<p>DeltaE value is: {DeltaE.getDeltaE00(color1, color2)}</p>
			</div>
		</ØFaq>
	);
};
const Øsquare1 = styled.div`
	z-index: 2;
	width: 10rem;
	height: 10rem;
	background: #${hexColor1};
`;
const Øsquare2 = styled.div`
	z-index: 2;
	width: 10rem;
	height: 10rem;
	background: #${hexColor2};
`;
const ØFaq = styled(ØSection)`
	display: block;
	span {
		display: block;
	}
	h2 {
		padding-bottom: 2rem;
		font-weight: lighter;
	}
	.faq-line {
		background: ${(props) => props.theme.colors.foreground02};
		height: 0.2rem;
		margin: 2rem 0rem;
		width: 100%;
	}
	.question {
		padding: 3rem 0rem;
		cursor: pointer;
	}
	.answer {
		padding: 2rem 0rem p {
			padding: 1rem 0rem;
		}
	}
`;
export default FaqSection;
