import { useState } from "react";
import styled, { css } from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../helpers/AuthContext";
import { AccountLogin } from "./components/AccountLogin";
const Login = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const LoginSchema = Yup.object().shape({
		password: Yup.string()
			.min(3, "Password should be at least 6 characters")
			.required("Required"),
	});
	const { login } = useAuth();

	return (
		<ØLogin>
			<div className='card'>
				<div className='card-body'>
					<h2>Login</h2>
					<Formik
						initialValues={{
							password: "",
						}}
						validationSchema={LoginSchema}
						onSubmit={(values) =>
							AccountLogin(values, setLoading, setError, login)
						}
					>
						<Form>
							<label htmlFor='password'>Password</label>
							<Field
								name='password'
								type='password'
								autoComplete='new-password'
							/>
							<ErrorMessage name='password' />
							<ØButton disabled={loading} type='submit'>
								Login
							</ØButton>

							{error && <p>{error}</p>}
						</Form>
					</Formik>
				</div>
				<div className='login-msg'>
					<p>Already have an account?... Good then</p>
				</div>
			</div>
		</ØLogin>
	);
};
const ØLogin = styled.div`
	.card {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.card-body {
			h2 {
				text-align: center;
			}
			width: 100%;
			max-width: 400px;
			border-radius: 1rem;
			padding: 3rem;
			border: 1px solid gray;
			form {
				display: flex;
				flex-direction: column;
				input {
					padding: 0.7rem 0rem;
					margin-bottom: 1.5rem;
				}
			}
		}
	}
`;
const ØButton = styled.button`
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	font-family: ${(props) => props.theme.logoFonts};
	font-weight: 600 !important;
	font-size: ${(props) => props.theme.fontSizes.xsmall};
	cursor: pointer;
	padding: 1.5rem 2rem;
	border: 3px solid ${(props) => props.theme.colors.foreground02};
	transition: all 0.5s ease;
	background-color: ${(props) => props.theme.colors.foreground02};
	color: ${(props) => props.theme.colors.background01};
	&:hover {
		background-color: ${(props) => props.theme.colors.foreground05};
		border: 3px solid ${(props) => props.theme.colors.foreground05};
		color: ${(props) => props.theme.colors.background01};
	}
	&:focus {
		outline: 0 !important;
	}
	${(props) =>
		props.outline &&
		css`
			background-color: transparent;
			color: ${(props) => props.theme.colors.foreground02};
		`};
	${(props) =>
		props.blue &&
		css`
			background-color: ${(props) => props.theme.colors.primary};
			border: 3px solid ${(props) => props.theme.colors.primary};
			color: ${(props) => props.theme.colors.background01};
			&:hover {
				background-color: ${(props) => props.theme.colors.primaryHover};
				border: 3px solid ${(props) => props.theme.colors.primaryHover};
			}
		`};
`;

export default Login;
