import history from "../../../helpers/history";

export async function AccountLogin(values, setLoading, setError, login) {
	const email = `user.${values.password
		.substring(0, 3)
		.toLowerCase()}@cedricguindon.com`;
	values = { ...values, email: email };
	// same shape as initial values
	try {
		setError("");
		setLoading(true);
		await login(email, values.password);
		setLoading(false);
		history.push("/");
	} catch {
		setError("Invalid Password");
		setLoading(false);
	}
}
