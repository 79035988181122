import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`

*{
	margin:0;
	padding:0;
	box-sizing: border-box;
}
html{
	@media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
		font-size: 50%; 
	}
font-size: 62.5%; 
}
body{
	b{
		font-weight: 600;
	}
	background: ${(props) => props.theme.colors.background03};
	font-family: ${(props) => props.theme.fonts};
	overflow-x: hidden;
}

button{
	font-family: ${(props) => props.theme.fonts};
	font-weight:bold;
	font-size: ${(props) => props.theme.fontSizes.xsmall};
	cursor: pointer;
	padding: 1rem 2rem;
	border: 3px solid ${(props) => props.theme.colors.primary};
	background:transparent;
	color: ${(props) => props.theme.colors.foreground01};
	transition: all 0.5s ease;
	&:hover{
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.foreground01};
	}
}
select{
	font-family: ${({ theme }) => theme.fonts}!important;
	font-weight: 400!important;
	font-size: ${({ theme }) => theme.fontSizes.small}!important;
	line-height: 2rem!important;
	height: 4rem!important;
	padding-top: 0rem!important;
	padding-bottom: 0rem!important;
}
h1{	
	@media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
	font-size: calc(${({ theme }) => theme.fontSizes.xlarge} * ${({ theme }) =>
	theme.mobileFontRatio.xlarge});
	}

	font-weight:400!important;	
	font-size: ${({ theme }) => theme.fontSizes.xlarge}!important;
	color: ${({ theme }) => theme.colors.foreground01};}
h2{
	@media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
	font-size: calc(${({ theme }) => theme.fontSizes.large} * ${({ theme }) =>
	theme.mobileFontRatio.large})!important;
	}

	font-weight:400!important;
	font-size: ${({ theme }) => theme.fontSizes.large}!important;
	color:${({ theme }) => theme.colors.foreground01};
}
h3{

	@media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
	font-size: calc(${({ theme }) => theme.fontSizes.medium} * ${({ theme }) =>
	theme.mobileFontRatio.medium})!important;
	}
	color:${({ theme }) => theme.colors.foreground01};
	font-size:${({ theme }) => theme.fontSizes.medium}!important;
}
h4{
	@media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
	font-size: calc(${({ theme }) => theme.fontSizes.mediumSmall} * ${({ theme }) =>
	theme.mobileFontRatio.mediumSmall})!important;
	}
	font-weight: 600;
	font-size:${({ theme }) => theme.fontSizes.mediumSmall}!important;
}
h5{
	@media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
	font-size: calc(${({ theme }) => theme.fontSizes.small} * ${({ theme }) =>
	theme.mobileFontRatio.small})!important;
	}
	font-weight: 600;
	font-size:${({ theme }) => theme.fontSizes.small}!important;
}
h1,h2,h3,h4,h5{
	line-height: 130%;
	color: ${({ theme }) => theme.colors.foreground01}!important;
	text-transform: none!important;
	font-family: ${({ theme }) => theme.logoFonts};
}

a,label, p, input, ul, select{
	@media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
	font-size: calc(${({ theme }) => theme.fontSizes.small} * ${({ theme }) =>
	theme.mobileFontRatio.small});
	}
	
	font-size: ${({ theme }) => theme.fontSizes.small};
}

label, p, ul, select{
	color: ${({ theme }) => theme.colors.foreground01};
}
ul{
	padding-left: 3rem;
}
`;
export default GlobalStyle;
