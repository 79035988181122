// img import
import Wave from "../components/Wave";
import home1 from "../img/home1.png";
// Styled
import {
	ØSection,
	ØDescription,
	ØHide,
	ØImageContainer,
} from "../style/StyledComponents";
// Framer motion animation
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../style/animation";

const AboutSection = ({ theme, setTheme }) => {
	const themeToggler = () => {
		theme === "light" ? setTheme("dark") : setTheme("light");
	};
	return (
		<ØSection>
			<ØDescription>
				<motion.div>
					<ØHide>
						<motion.h2 variants={titleAnim}>We work to make</motion.h2>
					</ØHide>
					<ØHide>
						<motion.h2 variants={titleAnim}>
							your <span>dreams</span> come
						</motion.h2>
					</ØHide>
					<ØHide>
						<motion.h2 variants={titleAnim}>true.</motion.h2>
					</ØHide>
				</motion.div>
				<motion.p variants={fade}>
					Contact us for any photography or videography ideas that you have. We
					are professionals with amazing skills
				</motion.p>
				<motion.button onClick={themeToggler} variants={fade}>
					Contact Us
				</motion.button>
			</ØDescription>
			<ØImageContainer>
				<motion.img variants={photoAnim} src={home1} alt='guy with a camera' />
			</ØImageContainer>
			<Wave />
		</ØSection>
	);
};
export default AboutSection;
