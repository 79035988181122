//* Animations
import { motion } from "framer-motion";
import { pageAnimation, titleAnim } from "../style/animation";
import styled from "styled-components";
import ScrollTop from "../components/ScrollTop";
const ContactUs = () => {
	return (
		<ØContactUs
			variants={pageAnimation}
			initial='hidden'
			animate='show'
			exit='exit'
		>
			<ScrollTop />
			<ØTitle>
				<ØHide>
					<motion.h2 variants={titleAnim}>Get in touch.</motion.h2>
				</ØHide>
			</ØTitle>
			<ØHide>
				<ØSocial variants={titleAnim}>
					<ØCircle />
					<h2>Send Us A Message</h2>
				</ØSocial>
			</ØHide>
			<ØHide>
				<ØSocial variants={titleAnim}>
					<ØCircle />
					<h2>Send An E-mail</h2>
				</ØSocial>
			</ØHide>
			<ØHide>
				<ØSocial variants={titleAnim}>
					<ØCircle />
					<h2>Social Media</h2>
				</ØSocial>
			</ØHide>
		</ØContactUs>
	);
};
const ØContactUs = styled(motion.div)`
	@media (max-width: 1300px) {
		padding: 2rem;
		font-size: 1rem;
	}
	background-color: white;
	padding: 5rem 10rem;
	color: #353535;
	min-height: 90vh;
`;

const ØTitle = styled.div`
	margin-bottom: 4rem;
	color: black;
	@media (max-width: 1300px) {
		margin-top: 5rem;
	}
`;
const ØHide = styled.div`
	overflow: hidden;
`;
const ØCircle = styled(motion.div)`
	border-radius: 3rem;
	width: 3rem;
	height: 3rem;
	background: #353535;
`;
const ØSocial = styled(motion.div)`
	display: flex;
	align-items: center;
	h2 {
		margin: 2rem;
	}
`;
export default ContactUs;
