import { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { MovieState } from "../movieState";
//* Animations
import { motion } from "framer-motion";
import { pageAnimation } from "../style/animation";

const MovieDetail = () => {
	const history = useHistory();
	const url = history.location.pathname;
	const movies = MovieState();
	const [movie, setMovie] = useState(null);
	// Use Effect
	useEffect(() => {
		const currentMovie = movies.filter((stateMovie) => stateMovie.url === url);
		setMovie(currentMovie[0]);
	}, [movies, url]);
	return (
		<>
			{movie && (
				<ØDetails
					variants={pageAnimation}
					initial='hidden'
					animate='show'
					exit='exit'
				>
					<ØHeadline>
						<h2>{movie.title}</h2>
						<img src={movie.mainImg} alt='movie' />
					</ØHeadline>
					<ØAwards>
						{movie.awards.map((award) => (
							<Award
								title={award.title}
								description={award.description}
								key={award.title}
							/>
						))}
					</ØAwards>
					<ØImageDisplay>
						<img src={movie.secondaryImg} alt='movie' />
					</ØImageDisplay>
				</ØDetails>
			)}
		</>
	);
};
const ØDetails = styled(motion.div)`
	color: ${(props) => props.theme.colors.foreground01};
`;
const ØHeadline = styled.div`
	min-height: 90vh;
	padding-top: 20vh;
	position: relative;
	h2 {
		position: absolute;
		top: 10%;
		left: 50%;
		transform: translate(-50%, -10%);
	}
	img {
		width: 100%;
		height: 70vh;
		object-fit: cover;
	}
`;
const ØAwards = styled.div`
	@media (max-width: 1300px) {
		display: block;
		margin: 2rem 2rem;
	}
	min-height: 80vh;
	display: flex;
	margin: 5rem 10rem;
	align-items: center;
	justify-content: space-around;
`;
const ØAward = styled.div`
	padding: 5rem;
	h3 {
		font-size: ${(props) => props.theme.fontSizes.medium};
	}
	.line {
		width: 100%;
		background: ${(props) => props.theme.colors.primary};
		height: 0.5rem;
		margin: 1rem 0rem;
	}
	p {
		padding: 2rem 0rem;
	}
`;
const ØImageDisplay = styled.div`
	min-height: 50vh;
	img {
		width: 100%;
		height: 100vh;
		object-fit: cover;
	}
`;

// Award Component
const Award = ({ title, description }) => {
	return (
		<ØAward>
			<h3>{title}</h3>
			<div className='line'></div>
			<p>{description}</p>
		</ØAward>
	);
};
export default MovieDetail;
