import styled from "styled-components";

const SignupSuccess = () => {
	return (
		<ØSignupSuccess>
			<div className='card'>
				<h2>The Account has been created</h2>
			</div>
		</ØSignupSuccess>
	);
};
const ØSignupSuccess = styled.div`
	.card {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.card-body {
			h2 {
				text-align: center;
			}
			width: 100%;
			max-width: 400px;
			border-radius: 1rem;
			padding: 3rem;
			border: 1px solid gray;
			form {
				display: flex;
				flex-direction: column;
				input {
					padding: 0.7rem 0rem;
					margin-bottom: 1.5rem;
				}
			}
		}
	}
`;
export default SignupSuccess;
