// *IMPORT
// React Modules Import
import { motion } from "framer-motion";
import { useState } from "react";

const Toggle = ({ children, title }) => {
	const [toggle, setToggle] = useState(false);
	return (
		<motion.div layout className='question' onClick={() => setToggle(!toggle)}>
			<motion.h3 layout> {title} </motion.h3>
			{toggle ? children : ""}
			<hr className='faq-line' />
		</motion.div>
	);
};
export default Toggle;
