import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
const firebaseConfig = {
	apiKey: "AIzaSyB5Ti9cxkoULv-RhIDlSAQh5mDQHpWwAOE",
	authDomain: "gdn-portfolio.firebaseapp.com",
	databaseURL: "https://gdn-portfolio.firebaseio.com",
	projectId: "gdn-portfolio",
	storageBucket: "gdn-portfolio.appspot.com",
	messagingSenderId: "485613269199",
	appId: "1:485613269199:web:4e38e7e0e03827d5e447ba",
	measurementId: "G-QL44TY5EZK",
};
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const db = {
	users: firestore.collection("users"),
	getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
};
export default firebase;
